@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.centered1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  padding: 5px;
  background: #EEEEEE;
}
.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  padding: 5px;
  background: #F5F5F5;
}
.evheader{
  width:100%;
  height: auto;
  object-fit:cover;
}
.evslider p{
  font-size: 14px;
  margin:2px;
  padding:2px;
  font-family: 'PT Sans','sans-serif';
}
.evsildernone {
  display: none;
  transition: all 0.5s ease; 
}

.Categoriesbox:hover .evsildernone {
  display: flex;
  flex-direction: column;
  animation: fadeIn 0.5s; 
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


.Categoriessection .Categoriemainbox {
  padding: 10px 26px;
}

.Categoriessection .Categoriesbox {
  background-color: #eef2f6;
  text-align: center;
  padding: 40px 11px;
  border-radius: 20px;
}

.Categoriessection .Categoriesbox a .Categorieiconbox img {
  width: 260px;
  height: 180px;
  object-fit: contain;
  display: flex;
  justify-content: center;
}
.Categoriessection .Categoriesbox:hover {
  background-color: #7a5bfd;
  transition: 1s;
  box-shadow: 0px 0px 3px 1px #7a5bfd;
}

.Categoriessection .Categoriesbox:hover h3 {
  color: #fff;
}

.Categoriessection .Categoriesbox:hover p {
  color: #fff;
}

.Categoriessection .Categoriesbox a .Categorieiconbox {
  margin: auto;
  padding: 23px;
  background-color: #fff;
  width: 290px;
  height: 240px;
  border-radius: 50px;
}

.slick-prev:before,
.slick-next:before {
  color: #7a5bfd;
}

@media (max-width: 1024px) {
  .Categoriessection .Categoriesbox a .Categorieiconbox {
    margin: auto;
    padding: 23px;
    background-color: #fff;
    width: 200px;
    height: 200px;
    border-radius: 50px;
  }
  .Categoriessection .Categoriesbox a .Categorieiconbox img {
    width: 170px;
    height: 160px;
    object-fit: contain;
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 800px) {
  .Categoriessection .Categoriesbox a .Categorieiconbox {
    margin: auto;
    padding: 23px;
    background-color: #fff;
    width: 190px;
    height: 190px;
    border-radius: 50px;
  }
  .Categoriessection .Categoriesbox a .Categorieiconbox img {
    width: 160px;
    height: 160px;
    object-fit: contain;
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 600px) {
  .Categoriessection .Categoriesbox a .Categorieiconbox {
    margin: none;
    padding: 10px;
    background-color: #fff;
    width: 120px;
    height: 120px;
    border-radius: 50px;
  }
  .Categoriessection .Categoriesbox a .Categorieiconbox img {
    width: 90px;
    height: 90px;
    object-fit: contain;
    display: flex;
    justify-content: center;
  }
  .centered {
    padding: 20px;
  }

  /* Additional styling */
  .StyledParagraph {
    line-height: 1.6;
  }

  .StyledParagraph ul {
    padding-left: 20px;
  }

  .StyledParagraph li {
    margin-bottom: 10px;
  }
}
