*
{
    margin: 0;
    padding: 0;
    color: #111111;
    /* color:rgb(30, 29, 29); */
}

#video
{
    position: fixed;
    z-index: -1;
    object-fit: cover;
    height: 100vh;
    width: 100%;
}

.card-background
{
    background-color: lightgrey;
}

.software-border
{
    border-bottom: 4px solid #9a39d2 !important;
}

.background-border
{
    border-bottom: 4px solid #43c93d !important;
}

.recruitment-border
{
    border-bottom: 4px solid #39d2b3 !important;
}

.project-border
{
    border-bottom: 4px solid #d53f3f !important;
}

.web-image
{
    max-width: 80% !important;
}

.span-red-bold
{
    color: #d53f3f;
}

.img-fluid-2
{
    height: 100% !important;
}

.employment-card
{
    border: 4px solid #af1a1e !important;
}

.security-card
{
    border: 4px solid #228fc7 !important;
}

.safety-card
{
    border: 4px solid #22c748 !important;
}

.legal-card
{
    border: 4px solid #ca610d !important;
}

.title-background
{
    background-color: #da1b2a;
    color: #ffffff !important;
    border-radius: 5px;
}

.led-card
{
    border: 1px solid #da1b2a !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
/* .container1:hover {
    transform: scale(1.03);
    box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
  } */



  .container1 {
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    /* background-color: rgba(17, 25, 40, 0.25); */
    border-radius: 12px;
    border: 2px solid red;
    padding: 38px;  
    filter: drop-shadow(0 30px  10px rgba(0,0,0,0.125));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    text-align: center;
    
  }
  
  .wrapper {
    width: 100%;
    height: 100%;
    
  }
  .banner-image1 {
    background-position: center;
    background-size: cover;
    height: 350px;
    width: 100%;
    border-radius: 12px;
    /* background-color: #43c93d; */
    /* border: 1px solid rgba(255,255,255, 0.255) */
  }
  .banner-image {
    background-position: center;
    background-size: cover;
    height: auto;
    width: 100%;
    border-radius: 12px;
    /* border: 1px solid rgba(255,255,255, 0.255) */
  }
  
   
  .item {
    color: #111111;
    font-family: 'Lato', sans-serif;
    text-align: center;
    margin-top: 20px;
    font-size: 0.8rem;
    line-height: 150%;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  
  .button-wrapper{
    margin-top: 18px;
  }
  
  /* .btn2 {
    border: none;
    padding: 12px 24px;
    border-radius: 24px;
    font-size: 12px;
    font-size: 0.8rem;  
    letter-spacing: 2px;  
    cursor: pointer;
  }
  
  .btn2 + .btn2 {
    margin-left: 10px;
  } */
  
  .outline {
    background: transparent;
    color: rgba(0, 212, 255, 0.9);
    border: 1px solid rgba(0, 212, 255, 0.6);
    transition: all .3s ease;
    
  }
  
  .outline:hover{
    transform: scale(1.125);
    color: rgba(255, 255, 255, 0.9);
    border-color: rgba(255, 255, 255, 0.9);
    transition: all .3s ease;  
  }
  
  .fill {
    background: rgba(0, 212, 255, 0.9);
    color: rgba(255,255,255,0.95);
    filter: drop-shadow(0);
    font-weight: bold;
    transition: all .3s ease; 
  }
  
  .fill:hover{
    transform: scale(1.125);  
    border-color: rgba(255, 255, 255, 0.9);
    filter: drop-shadow(0 10px 5px rgba(0,0,0,0.125));
    transition: all .3s ease;    
  }
  .led{
    width: 300px;
    height: 300px;
  }
  /* .nav-link:hover .nav-link1 {
    background-color: lightgreen;
    display: block;
} */
.dropdown-item{
  background-color: #f31f2d;
}
/* .dropdown-item:hover{
  color: #0f60d9;
  background-color: #eee;
} */