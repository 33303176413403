
.navb{
    color:rgb(235, 178, 178);

}
.navb:hover{
    background-color: #da1b2a;
    color:#ebe5e5;
}

